
import { Component, Vue, Watch } from 'vue-property-decorator'
import TheNews from '@/components/new/TheNews.vue'

@Component({
  components: {
    TheNews
  },
  metaInfo() {
    return{
    title: this.$tc('caption'),
  }}
})

export default class NewsPage extends Vue {
  page = 1
  pageType = ''

  @Watch('$route')
  onWatchRouting () {
    this.getPage()
    this.getTypePage()
  }

  checkLocale () {
    const lang = this.$route.params.lang
    if (this.$root.$i18n.locale === lang) return
    this.$root.$i18n.locale = lang
    localStorage.lang = lang
  }

  getPage () {
    this.page = this.$route.params.page ? + this.$route.params.page : 1
  }

  getTypePage () {
    this.pageType = this.$route.params.type ? this.$route.params.type : ''
  }

  created () {
    this.checkLocale()
    this.getPage()
    this.getTypePage()
  }
}
