
import '@/assets/styles/imports/news.sass'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { NewsInterface } from '@/interfaces/news'
import NewsItem from '@/components/_new/NewsItem.vue'
import PaginationTwo from '@/components/_tools/PaginationTwo.vue'
import api from '@/api'

@Component({
  components: {
    NewsItem,
    PaginationTwo
  }
})

export default class TheNews extends Vue {
  @Prop({ type: Number, default: 1 }) readonly page!: number
  @Prop({ type: String, default: '' }) readonly pageType!: string
  count = 0
  pageLength = 45
  news: Array<NewsInterface.New> = []

  @Watch('page')
  onWatchPage () {
    this.fetchNews()
  }

  @Watch('pageType')
  onWatchType () {
    this.fetchNews()
  }

  async fetchNews () {
    const news = await api.news.get({
      orderBy: 'Decreasing',
      newsType: this.pageType,
      skip: this.getSkipNews(this.page),
      take: this.pageLength
    })
    if (!news || !news.items.length) return this.$router.push({ name: '404', params: { lang: this.$route.params.lang }})
    this.count = news.itemsCount
    this.news = news.items
    window.scrollTo(0, 0)
  }

  getSkipNews (page: number): number {
    if (page < 2) return 0
    return (page - 1) * this.pageLength
  }

  get currentCount () {
    const count = this.page * this.pageLength
    if (count > this.count) return this.count
    return count
  }

  get locale () {
    return this.$i18n.locale === 'ru' ? 'ru-RU' : 'en-US'
  }

  get countPage () {
    return Math.ceil(this.count / this.pageLength)
  }

  get routePageName (): string {
    if (this.pageType) return `/${this.$i18n.locale}/news/${this.pageType}`
    return `/${this.$i18n.locale}/news`
  }

  created () {
    this.fetchNews()
  }
}
