
import '@/assets/styles/imports/news-item.sass'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class NewsItem extends Vue {
  @Prop({ type: Number, default: 1 }) id!: number
  @Prop({ type: String, default: '' }) date!: string
  @Prop({ type: String, default: 'ru-RU' }) locale!: string
  @Prop({ type: String, default: '' }) name!: string
  @Prop({ type: String, default: '' }) note!: string

  get dateFormat () {
    const date: Date = new Date(this.date)
    return date.toLocaleDateString(this.locale, { day: 'numeric', month: 'long', year: 'numeric' })
  }
}
