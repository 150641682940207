
import '@/assets/styles/tools/pagination.sass'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class PaginationTwo extends Vue {
  @Prop(String) routerName!: string
  @Prop(Number) currentPage!: number
  @Prop(Number) countPages!: number

  getRoute (page: number): string {
    if (page === 1) return this.routerName
    return `${this.routerName}/page/${page}`
  }

  getStartCount () {
    if (this.currentPage < 10) return 1
    if (this.currentPage - 5 > 0) return this.currentPage - 5
    return 1
  }

  getEndCount () {
    if (this.currentPage + 10 > this.countPages) return this.countPages
    if (this.currentPage + 5 > this.countPages) return this.countPages
    return this.currentPage + 5
  }

  get prevPage (): string {
    if (this.currentPage - 1 === 1) return this.routerName
    return `${this.routerName}/page/${this.currentPage - 1}`
  }

  get nextPage (): string {
    return `${this.routerName}/page/${this.currentPage + 1}`
  }

  get pages () {
    const pages = []
    if (this.countPages < 15) {
      for (let i = 1; i <= this.countPages; i++) { pages.push(i) }
      return pages
    }
    const start = this.getStartCount()
    const end = this.getEndCount()
    if (start > 4) pages.push(1,2,3,'...')
    for (let i = start; i <= end; i++) { pages.push(i) }
    if (end < this.countPages - 2) {
      pages.push('...')
      for (let i = this.countPages - 2; i <= this.countPages; i++) { pages.push(i) }
    }
    return pages
  }
}
